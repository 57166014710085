export const questionsEmployer = [
  { uid: "welcome", type: "welcome", question: "welcome" },
  {
    uid: "q1",
    type: "multiple option",
    section: "",
    question:
      "With health costs rising at a predicted 8.5% in 2024, what are you discovering about your organization & employee needs?",
    aclaration: "Multiple choice, more than one answer is allowed:",
    options: [
      "Culture and workforce development are priorities.",
      "Expense reduction is a priority.",
      "Enhancing the benefit offering is more important than ever.",
      "The need for reporting tools & claims data is greater.",
      "Increased awareness, demand & concern around GLP1 medications & other obesity management drugs.",
      "Emphasis on mental health.",
      "Greater flexibility around hybrid work environment.",
      "Other",
    ],
    other: true,
    minOptions: 1,
    maxOptions: 9,
  },
  {
    uid: "q2",
    type: "single option",
    section: "",
    question:
      "How much more engaged are you & your C-Suite team in the health plan decisions this year vs last year?",
    options: [
      "More than 10%",
      "More than 25%",
      "More than 50%",
    ],
  },
  {
    uid: "q3",
    type: "single option",
    section: "",
    question:
      "If you are fully insured today, have you proactively asked your health insurance advisor about alternative or self-funded solutions?",
    options: [
      "Yes.",
      "No, because we (or our advisor) passed up on alternative options in the past.",
      "No, because I'm unfamiliar with any alternative solution.",
    ],
  },
  {
    uid: "q4",
    type: "single option",
    section: "",
    question:
      "What method of funding do you believe is in the best interest of your company's long-term strategy?",
    options: ["Fully Insured", "Self-Insured"],
  },
  {
    uid: "q5",
    type: "single option",
    section: "",
    question:
      "I believe working with the BUCAHs (Blues, United, Cigna, Aetna, Humana, etc.) is best for my business & employees because of the name recognition and safety of working with a large national insurance carrier.",
    options: [
      "True",
      "False",
    ],
  },
  {
    uid: "q6",
    type: "single option",
    section: "",
    question:
      "What is the percentage of potential savings you would need to see (versus fully insured premiums) before you would consider changing to self-funding?",
    options: ["5%", "10%", "15%", "20%", "25% or greater"],
  },
  {
    uid: "q7",
    type: "single option",
    section: "",
    question:
      "In 2023, did your employees postpone a medical treatment, procedure, or surgery due to high cost or inability to pay?",
    options: [
      "Yes",
      "No",
    ],
  },
  {
    uid: "q8",
    type: "multiple option",
    section: "",
    question:
      "What transparency tools would be most beneficial for your employees or help enhance your benefit offerings for talent acquisition/retention?",
    aclaration: "Please choose 3:",
    options: [
      "Fair pricing estimators.",
      "Live advocates to help locate quality providers.",
      "Incentives to use high-quality, lower-cost providers.",
      "Reporting tools for provider quality standards.",
      "Plan Performance Reviews to analyze utilization & trends.",
      "Other",
    ],
    other: true,
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q9",
    type: "single option",
    section: "",
    question:
      "With persistent inflation, labor shortages & an upcoming election, your company:",
    options: [
      "Still prefers a turn-key health plan run by the traditional health insurance companies.",
      "Is open to the autonomy of building a health plan that places them in control.",
      "Prefers a combination of the two.",
    ],
  },
  {
    uid: "q10",
    type: "single option",
    section: "",
    question:
      "My Executive Team understands that our health insurance plan (when managed effectively) is our #1 negotiable operating expense.",
    options: ["True", "False"],
  },
  {
    uid: "q11",
    type: "single option",
    section: "",
    question:
      "With the rise of high-cost specialty drugs, is your company equipped to reduce the costs in 2025?",
    options: [
      "Yes, because of the current visibility into the data.",
      "Yes, because we're working to gain access to the data.",
      "No, because there are too many variables or plan limitations with the current provider or TPA.",
      "Other",
    ],
    other: true,
  },
  {
    uid: "q12",
    type: "single option",
    section: "",
    question:
      "For those who were fully insured before, what was the deciding factor in choosing a self-funded strategy?",
    options: [
      "Desire for transparency of what's driving our costs.",
      "Need for information to make informed decisions.",
      "Frustration over renewal rate increases every year.",
      "Rising pharmacy spending or lack of transparency.",
      "We are currently fully-insured.",
    ],
  },
  {
    uid: "q13",
    type: "multiple option",
    section: "",
    question:
      "What are your top concerns when making group health plan decisions?",
    aclaration: "Please choose 3:",
    options: [
      "Cost.",
      "Service and Responsiveness.",
      "Access to the highest-quality doctors.",
      "Largest network.",
      "Employee resistance.",
      "Care coordination & navigation.",
      "Other",
    ],
    other: true,
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q14",
    type: "multiple option",
    section: "",
    question:
      "If you expressed challenges with your health plan & addressed specific concerns to your advisor, what were the driving influencers?",
    aclaration: "Please choose 3:",
    options: [
      "Cost concerns.",
      "Specific employee feedback/input.",
      "The need for better talent acquisition & retention.",
      "Advisor recommendations-yours or a previous consultants.",
      "Visibility into claims data & the Rx spend.",
      "A lack of transparency & reporting tools.",
      "Other",
    ],
    other: true,
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q15",
    type: "single option",
    section: "",
    question:
      "If you knew there were solutions available to guide members to higher-quality/lower-cost providers & positively impact healthcare costs, what would prevent you from adopting these strategies?",
    options: [
      "Lack of understanding.",
      "Lack of education.",
      "Disbelief there is a large enough gap in provider cost and quality to make a difference.",
      "Fear that employees will not be able to see the provider their primary care doctor recommended.",
    ],
  },
  {
    uid: "q16",
    type: "multiple option",
    section: "",
    question:
      "What are your strategic healthcare priorities over the next 3 years?",
    aclaration: "Please choose 3:",
    options: [
      "Managing high-cost claimants.",
      "Reducing Specialty Rx spend.",
      "Creating a culture of health.",
      "Increasing telemedicine use.",
      "Enhanced behavioral health programs.",
      "Improving patient engagement with advocacy services.",
      "Rise of GLP1-s & obesity management drugs.",
    ],
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q17",
    type: "multiple option",
    section: "",
    question:
      "What are the minimum capability requirements for your insurance advisor and/or health plan administrator?",
    aclaration: "Please choose 3:",
    options: [
      "High-touch service & proactive account management.",
      "Transparency.",
      "Navigation to high-quality/low-cost providers.",
      "Actionable data analytics.",
      "Flexibility to customize services to our needs.",
    ],
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q18",
    type: "single option",
    section: "",
    question:
      "Does your insurance advisor bring vendors or experienced solution experts into your renewal meetings?",
    options: [
      "Yes.",
      "No.",
      "Only when the advisor was trying to win our business.",
    ],
  },
  {
    uid: "q19",
    type: "single option",
    section: "",
    question:
      "If you have shifted to self-funding or adopted alternative strategies, how often are the desired outcomes implemented/achieved?",
    options: [
      "We're seeing timely success with desired outcomes.",
      "We're seeing slow adoption with some success.",
      "We're seeing minimal improvements but staying on course.",
      "We're considering going back to fully insured.",
    ],
  },
  {
    uid: "q20",
    type: "single option",
    section: "",
    question:
      "How many months prior to renewal does your insurance broker/advisor discuss alternative options to improve the health plan and the member experience?",
    options: [
      "9 months prior.",
      "6 months prior.",
      "3 months prior.",
      "During the annual renewal.",
    ],
  },
  {
    uid: "q21",
    type: "ranked option",
    section: "",
    question:
      "What would be the best resources to help you understand new ways to optimize your health plan?",
    aclaration: "Rank in order, choose 3:",
    options: [
      "Accelerator workshops for a deeper dive into the mechanics and case studies.",
      "Live casts/digital events for awareness.",
      "Fundamentals/essentials trainings on self-funding practices and strategies.",
      "Industry book with expert contributors and success stories.",
      "Digital shorts on various self-funding topics.",
      "Other",
    ],
    other: true,
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q22",
    type: "triple dropdown",
    question: `To serve the community with valuable data & insights, we appreciate your input on the following questions:`,
  },
  { uid: "finish", type: "finish", question: "finish" },
];