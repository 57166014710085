export const questionsBroker = [
  { uid: "welcome", type: "welcome", question: "welcome" },
  {
    uid: "q1",
    type: "multiple option",
    section: "",
    question:
      "With health costs rising at a predicted 8.5% in 2024, what are you discovering about your clients & prospects?",
    aclaration: "Multiple choice, more than one answer is allowed:",
    options: [
      "Culture and workforce development are priorities.",
      "Expense reduction is a priority.",
      "Enhancing the benefit offering is more important than ever.",
      "Rx cost control is a priority.",
      "The demand for reporting tools & claims data is greater.",
      "Increasingly concerned with the rising popularity of GLP1 medications & other obesity management drugs.",
      "Strong emphasis on mental health.",
      "Greater flexibility around hybrid work environment.",
      "Other",
    ],
    other: true,
    minOptions: 1,
    maxOptions: 9,
  },
  {
    uid: "q2",
    type: "single option",
    section: "",
    question:
      "What percent of your C-Suite executive clients are more engaged in health plan decisions this year vs. last year?",
    options: [
      "More than 10%",
      "More than 25%",
      "More than 50%",
    ],
  },
  {
    uid: "q3",
    type: "single option",
    section: "",
    question:
      "A 2024 Business Group on Health Employer Survey shared that almost 40% of employers are planning to make changes to other health & well-being vendors. Based on that statistic, how do you plan to spend your time?",
    options: [
      "Meeting with clients more frequently to demonstrate value and share cost reduction strategies.",
      "Focus on prospecting to increase my block of business.",
      "No change. Keep doing what I am doing today.",
    ],
  },
  {
    uid: "q4",
    type: "single option",
    section: "",
    question:
      "What percentage of your clients and prospects proactively ask about alternative or self-funded solutions?",
    options: [
      "More than 10%",
      "More than 25%",
      "More than 50%",
    ],
  },
  {
    uid: "q5",
    type: "single option",
    section: "",
    question:
      "What method of funding do you believe is in the best interest of your customers' long-term strategy?",
    options: ["Fully Insured", "Self-Insured"],
  },

  {
    uid: "q6",
    type: "single option",
    section: "",
    question:
      "What percentage of your business is self-funded vs. fully insured?",
    options: [
      "20% of business",
      "40% of business",
      "60% of business",
      "80% of business",
    ],
  },

  {
    uid: "q7",
    type: "single option",
    section: "",
    question:
      "Many of my clients believe working with BUCAHs (Blues, United, Cigna, Aetna, Humana, etc.) is in their best interest because of the name recognition and safety of working with a large national insurance carrier.",
    options: [
      "True",
      "False",
      "I believe this & so do my clients.",
    ],
  },
  {
    uid: "q8",
    type: "multiple option",
    section: "",
    question:
      "What transparency tools do your clients most value for cost & quality transparency?",
    aclaration: "Please choose 3:",
    options: [
      "Fair pricing estimators.",
      "Live advocates to help locate quality providers.",
      "Incentives to use high-quality, lower-cost providers.",
      "Reporting tools for provider quality standards.",
      "Plan Performance Reviews to analyze utilization & trends.",
      "Other",
    ],
    other: true,
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q9",
    type: "single option",
    section: "",
    question:
      "The C-Suite understands their health insurance plan (when managed effectively) is their #1 negotiable operating expense.",
    options: ["True", "False"],
  },
  {
    uid: "q10",
    type: "single option",
    section: "",
    question:
      "With persistent inflation, labor shortages & an upcoming election, do your clients:",
    options: [
      "Still prefer a turn-key health plan run by the traditional health insurance companies.",
      "Are open to the autonomy of building a health plan that places them in control.",
      "Prefer a combination of the two.",
    ],
  },
  {
    uid: "q11",
    type: "single option",
    section: "",
    question:
      "With the rise of high-cost specialty drugs, are your clients equipped to reduce the costs in 2024 & 2025?",
    options: [
      "Yes, because of their current visibility into the data.",
      "Yes, because we're working to gain access to the data.",
      "No, because there are too many variables or plan limitations with their current provider or TPA.",
      "Other",
    ],
    other: true,
  },
  {
    uid: "q12",
    type: "multiple option",
    section: "",
    question:
      "For those clients that choose self-funding, what was the deciding factor?",
    aclaration: "Multiple choice, more than one answer is allowed:",
    options: [
      "Desire for transparency of what's driving their costs.",
      "Ability to gain full access to data to make informed decisions.",
      "Frustration over renewal rate increases every year.",
      "Rising pharmacy spending or lack of transparency.",
      "Savings greater than 10%.",
    ],
    minOptions: 1,
    maxOptions: 5,
  },
  {
    uid: "q13",
    type: "multiple option",
    section: "",
    question:
      "What are the top concerns of your C-Suite customers or prospects when making decisions for their group health plan?",
    aclaration: "Please choose 3:",
    options: [
      "Cost.",
      "Service and Responsiveness.",
      "Access to the highest-quality doctors.",
      "Largest network.",
      "Employee resistance.",
      "Care coordination & navigation.",
    ],
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q14",
    type: "multiple option",
    section: "",
    question:
      "If/when your clients or prospects actively express challenges with their health plan & address specific concerns, what are the most common influencers?",
    aclaration: "Please choose 3:",
    options: [
      "Cost concerns.",
      "Specific employee feedback/input.",
      "The need for better talent acquisition & retention.",
      "Advisor recommendations (yours or a previous consultant's).",
      "Visibility into claims data & the Rx spend.",
      "A lack of transparency & reporting tools.",
      "Other",
    ],
    other: true,
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q15",
    type: "single option",
    section: "",
    question:
      "If guiding members to higher-quality/lower-cost providers positively impacts healthcare costs, why do you think it's slow for employers and employees to adopt these strategies?",
    options: [
      "Lack of understanding.",
      "Lack of education.",
      "Disbelief there is a large enough gap in provider cost and quality to make a difference.",
      "Fear that employees will not be able to see the provider their primary care doctor recommended.",
    ],
  },
  {
    uid: "q16",
    type: "multiple option",
    section: "",
    question:
      "What strategic healthcare priorities do your clients have over the next 3 years?",
    aclaration: "Please choose 3:",
    options: [
      "Managing high-cost claimants.",
      "Reducing Specialty Rx spend.",
      "Creating a culture of health.",
      "Increasing telemedicine use.",
      "Enhanced behavioral health programs.",
      "Improving patient engagement with advocacy services.",
      "Rise of GLP1-s & obesity management drugs.",
    ],
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q17",
    type: "single option",
    section: "",
    question:
      "How many months prior to renewal do you begin speaking with your clients about alternative options to improve the health plan and the member experience?",
    options: [
      "9 months prior.",
      "6 months prior.",
      "3 months prior.",
      "During the annual renewal.",
      "All year long, I never stop.",
    ],
  },
{
    uid: "q18",
    type: "ranked option",
    section: "",
    question:
      "What type of communication do you need to effectively educate on the benefits of self-funding to your clients?",
    aclaration: "Rank in order, choose 3:",
    options: [
      "I need information that helps me communicate the basics of why self-funding may make sense for my client.",
      "I get it and my client gets it, but we need something that speaks to the mechanics of how self-funding works and how it compares to their current fully insured arrangement.",
      "I need an easy-to-read and understand FAQ for companies and employees.",
      "I would love a video that I can share to my prospects and clients explaining the benefits of self-funding.",
    ],
    other: false,
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q19",
    type: "multiple option",
    section: "",
    question:
      "What capabilities/option(s) are minimum requirements for your health plan administrator/TPA?",
    aclaration: "Please choose 3:",
    options: [
      "High-touch service & proactive account management.",
      "Transparency.",
      "Navigation to high-quality/low-cost providers.",
      "Actionable data analytics.",
      "Flexibility to customize services to our needs.",
    ],
    minOptions: 3,
    maxOptions: 3,
  },
  {
    uid: "q20",
    type: "single option",
    section: "",
    question:
      "How often do you bring an experienced solution partner to your renewal or finalist meetings?",
    options: [
      "More than 10%",
      "More than 25%",
      "More than 50%",
      "More than 75%",
      "I never bring an outside entity with me.",
    ],
  },
  {
    uid: "q21",
    type: "single option",
    section: "",
    question:
      "What percent of your book of business had you in a competitive situation this past year where the client was shopping for a new broker?",
    options: [
      "Less than 10%",
      "More than 10%",
      "More than 25%",
    ],
  },
  {
    uid: "q22",
    type: "single option",
    section: "",
    question:
      "If your clients have shifted to self-funding or adopted alternative strategies, how often are the desired outcomes implemented/achieved?",
    options: [
      "We're seeing timely success with desired outcomes.",
      "We're seeing slow adoption with some success.",
      "We're seeing minimal improvements but staying on course.",
      "Most of the clients are choosing to go back to fully insured.",
    ],
  },
  {
    uid: "q23",
    type: "triple dropdown",
    question: `To serve the community with valuable data & insights, we appreciate your input on the following questions:`,
  },
  { uid: "finish", type: "finish", question: "finish" },
];